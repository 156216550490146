(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vue"));
	else if(typeof define === 'function' && define.amd)
		define(["Vue"], factory);
	else if(typeof exports === 'object')
		exports["EpiCurrents_app"] = factory(require("Vue"));
	else
		root["EpiCurrents_app"] = factory(root["Vue"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__2740__) => {
return 