/**
 * EpiCurrents Viewer internationalization main script.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import { createI18n } from 'vue-i18n';
import { messagesEN, datetimeUS } from './locales/en';
import { messagesFI, datetimeFI } from './locales/fi';
let i18n = null;
export const availableLocales = ['en', 'fi'];
export const capitalize = (text) => {
    if (!text.length) {
        return text;
    }
    return text.substring(0, 1).toLocaleUpperCase() + text.substring(1);
};
/**
 * Initialize Vue-i18n module and return the initialized object.
 * @param vueInstance Vue instance to use the i18n in
 * @param locale desired active locale
 * @returns I18n
 */
export const init = (vueInstance, locale) => {
    // Initialization
    const messages = {
        en: messagesEN,
        fi: messagesFI,
    };
    const dateTimeFormats = {
        'en-US': datetimeUS,
        'fi-FI': datetimeFI,
    };
    i18n = createI18n({
        locale: locale,
        fallbackLocale: 'en',
        messages: messages,
        dateTimeFormats: dateTimeFormats,
        silentFallbackWarn: true,
        silentTranslationWarn: true,
    });
    vueInstance.use(i18n);
    return i18n;
};
/**
 * Return a translation string for the given key, primarily from a
 * component-specific translation, secondarily from a general translation.
 * @param component name of the component (empty string or undefined for global)
 * @param key string to translate
 * @param params optional params
 */
export const T = (key, component, params, capitalized) => {
    if (key.length && i18n) {
        // @ts-ignore: These types are currently faulty and give an incorrect error if not ignored
        const specific = i18n.global.t(`components.${component}.${key}`, params);
        if (component && !specific.startsWith(`components.${component}`)) {
            return capitalized ? capitalize(specific) : specific;
        }
        else {
            // This will use the key as a fallback if no general translation is found
            // @ts-ignore
            return capitalized ? capitalize(i18n.global.t(key, params)) : i18n.global.t(key, params);
        }
    }
    else {
        return '';
    }
};
