/**
 * EpiCurrents Viewer Finnish locale master file.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
const locApp = require('./fi/App.json');
const messagesFI = {
    components: {
        App: locApp,
    },
    datetime: '{d}.{m}.{y} {h}:{min}',
};
const datetimeFI = {
    short: {
        year: 'numeric', month: 'numeric', day: 'numeric',
    },
    long: {
        year: 'numeric', month: 'short', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
    },
};
export { messagesFI, datetimeFI };
