/**
 * The main app component to add to the container div.
 */
import Log from 'scoped-ts-log';
import { defineComponent, defineAsyncComponent, ref } from 'vue';
import { T } from 'ROOT/i18n';
// Child components
const AppIcon = defineAsyncComponent(() => import('COMPONENTS/mixins/AppIcon.vue'));
const AppInterface = defineAsyncComponent(() => import(`./AppInterface.vue`));
export default defineComponent({
    name: 'App',
    components: {
        AppIcon,
        AppInterface,
    },
    setup(props, { attrs, slots, emit }) {
        const fileContext = ref('');
        const loading = ref(true);
        const styles = ref([]);
        return {
            fileContext,
            loading,
            styles,
        };
    },
    watch: {
        fileContext(value) {
        }
    },
    methods: {
        /**
         * Override the default I18n translate method.
         * Returns a component-specific translation (default) or a
         * general translation (fallback) for the given key string.
         */
        $t: function (key, params = {}, capitalized = false) {
            return T(key, this.$options.name, params, capitalized);
        },
        /**
         * Add new styles to the shadow root.
         * @param containerId id of the root container, only needed on the first time
         */
        addStyles(classNames) {
            // Load FontAwesome styles only on first go
            const initial = !this.styles.length ? true : false;
            for (const className of classNames) {
                for (const styleList of document.styleSheets) {
                    for (let i = 0; i < styleList.cssRules.length; i++) {
                        // Remove backslashes added to cssRules to escape CSS reserved characters
                        // for matching, but add the original text to style list.
                        const definitions = styleList.cssRules.item(i)?.cssText?.replaceAll('\\', '');
                        if (definitions && (definitions.startsWith(`.${className}`) ||
                            (initial && definitions.match(/[\s|\.]fa-/)))) {
                            this.styles.push(styleList.cssRules.item(i)?.cssText);
                        }
                    }
                }
            }
            // Select the shadow root style element and inject app styles
            const styleTag = document.querySelector(`#epicv${this.$store.state.containerId}`)?.shadowRoot?.querySelector('div')?.querySelector('style');
            if (styleTag) {
                styleTag.innerHTML = this.styles.join('\n');
            }
        },
        /**
         * Handle study folder selection.
         */
        async handleDatasetSelect(event) {
            if (!event.target?.files) {
                return;
            }
            const folder = {
                name: event.target.files[0].webkitRelativePath.split('/')[0],
                path: '/',
                type: 'directory',
                directories: [],
                files: [],
            };
            const fileList = event.target.files;
            for (const file of fileList) {
                folder.files.push({
                    name: file.name,
                    path: `/${file.name}`,
                    type: 'file',
                    file: file,
                    directories: [],
                    files: [],
                });
            }
            await this.openDataset(folder);
            this.fileContext = '';
        },
        /**
         * Handle file selection.
         */
        async handleFileSelect(event) {
            const selected = event.target.files;
            if (selected.length) {
                this.openFile(selected[0]);
            }
            this.fileContext = '';
        },
        /**
         * Handle file folder selection.
         */
        async handleFolderFilesSelect(event) {
            const fileList = event.target.files;
            for (const file of fileList) {
                await this.openFile(file);
            }
            this.fileContext = '';
        },
        /**
         * Handle study folder selection.
         */
        async handleFolderStudySelect(event) {
            if (!event.target?.files) {
                return;
            }
            const folder = {
                name: event.target.files[0].webkitRelativePath.split('/')[0],
                path: '/',
                type: 'directory',
                directories: [],
                files: [],
            };
            const fileList = event.target.files;
            for (const file of fileList) {
                folder.files.push({
                    name: file.name,
                    path: `/${file.name}`,
                    type: 'file',
                    file: file,
                    directories: [],
                    files: [],
                });
            }
            await this.openFolderStudy(folder);
            this.fileContext = '';
        },
        /**
         * Trigger when mouse leaves the app area
         */
        mouseLeftApp() {
            this.$store.dispatch('mouse-left-app');
        },
        /**
         * Trigger a click on the open file folder input to display the folder picker.
         */
        openDatasetClick(context) {
            this.fileContext = context;
            this.$refs['open-dataset'].dispatchEvent(new MouseEvent('click'));
        },
        openDataset(folder, context) {
            this.$store.dispatch('load-dataset-folder', { folder: folder, name: folder.name, context: context || this.fileContext });
        },
        /**
         * Display an open file dialog and handle the selection.
         */
        async openFile(file, context) {
            const fileUrl = URL.createObjectURL(file);
            return await this.$store.dispatch('load-study-url', { url: fileUrl, scope: 'local', name: file.name, context: context || this.fileContext });
        },
        /**
         * Trigger a click on the open folder input to display the file picker.
         */
        async openFileClick(context) {
            this.fileContext = context;
            // Use FilePicker API if available, else just use the file input
            if (window.showOpenFilePicker) {
                const [fileHandle] = await window.showOpenFilePicker();
                this.openFile(await fileHandle.getFile());
                this.fileContext = '';
            }
            else {
                ;
                this.$refs['open-file'].dispatchEvent(new MouseEvent('click'));
            }
        },
        /**
         * Trigger a click on the open file folder input to display the folder picker.
         */
        openFolderFilesClick(context) {
            this.fileContext = context;
            this.$refs['open-folder-files'].dispatchEvent(new MouseEvent('click'));
        },
        openFolderStudy(folder, context) {
            this.$store.dispatch('load-study-folder', { folder: folder, name: folder.name, context: context || this.fileContext });
        },
        /**
         * Trigger a click on the open study folder input to display the folder picker.
         */
        openFolderStudyClick(context) {
            this.fileContext = context;
            this.$refs['open-folder-study'].dispatchEvent(new MouseEvent('click'));
        },
    },
    mounted() {
        // Do not add any styles in this component, the global styles are added in interface!
        // Listen to style update requests
        this.$store.subscribe((mutation) => {
            Log.debug(`Store commit event ${mutation.type}.`, this.$options.name);
            if (mutation.type === 'add-styles') {
                this.addStyles(mutation.payload);
            }
        });
    },
});
