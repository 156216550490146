import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_icon = _resolveComponent("app-icon");
    const _component_app_interface = _resolveComponent("app-interface");
    return (_openBlock(), _createElementBlock("div", {
        ref: "app",
        class: "epicv-app",
        onMouseleave: _cache[9] || (_cache[9] =
            //@ts-ignore
            (...args) => (_ctx.mouseLeftApp && _ctx.mouseLeftApp(...args)))
    }, [
        (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_app_icon, {
                key: 0,
                icon: 'spinner',
                spin: true,
                style: { "display": "none" }
            }))
            : _createCommentVNode("v-if", true),
        _createVNode(_component_app_interface, {
            onOpenDataset: _cache[0] || (_cache[0] = ($event) => (_ctx.openDatasetClick($event))),
            onOpenFile: _cache[1] || (_cache[1] = ($event) => (_ctx.openFileClick($event))),
            onOpenFolderFiles: _cache[2] || (_cache[2] = ($event) => (_ctx.openFolderFilesClick($event))),
            onOpenFolderStudy: _cache[3] || (_cache[3] = ($event) => (_ctx.openFolderStudyClick($event)))
        }),
        (_ctx.$store.state.showOverlay)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([
                    'epicv-overlay',
                    { 'settings': _ctx.$store.state.settingsOpen },
                ]),
                onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.$store.dispatch('overlay-clicked')))
            }, null, 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
        _createCommentVNode(" File inputs for opening a select file or folder dialog "),
        _createElementVNode("input", {
            type: "file",
            ref: "open-file",
            style: { "visibility": "hidden" },
            multiple: "true",
            onChange: _cache[5] || (_cache[5] =
                //@ts-ignore
                (...args) => (_ctx.handleFileSelect && _ctx.handleFileSelect(...args)))
        }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */),
        _createElementVNode("input", {
            type: "file",
            ref: "open-folder-files",
            style: { "visibility": "hidden" },
            webkitdirectory: "true",
            directory: "true",
            multiple: "true",
            onChange: _cache[6] || (_cache[6] =
                //@ts-ignore
                (...args) => (_ctx.handleFolderFilesSelect && _ctx.handleFolderFilesSelect(...args)))
        }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */),
        _createElementVNode("input", {
            type: "file",
            ref: "open-folder-study",
            style: { "visibility": "hidden" },
            webkitdirectory: "true",
            directory: "true",
            multiple: "true",
            onChange: _cache[7] || (_cache[7] =
                //@ts-ignore
                (...args) => (_ctx.handleFolderStudySelect && _ctx.handleFolderStudySelect(...args)))
        }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */),
        _createElementVNode("input", {
            type: "file",
            ref: "open-dataset",
            style: { "visibility": "hidden" },
            webkitdirectory: "true",
            directory: "true",
            multiple: "true",
            onChange: _cache[8] || (_cache[8] =
                //@ts-ignore
                (...args) => (_ctx.handleDatasetSelect && _ctx.handleDatasetSelect(...args)))
        }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */)
    ], 544 /* HYDRATE_EVENTS, NEED_PATCH */));
}
