/**
 * EpiCurrents Viewer English locale master file.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import locApp from './en/App.json';
import locEegViewer from './en/EegViewer.json';
import locExamineTool from './en/ExamineTool.json';
const messagesEN = {
    components: {
        App: locApp,
        EegViewer: locEegViewer,
        ExamineTool: locExamineTool,
    },
    datetime: '{y}/{m}/{d} {h}:{min}',
    'Duration: {t}': 'Duration: {t}',
    'Number {n}': 'Number {n}',
    'uV': 'µV',
    '{n} channels': '{n} channel | {n} channels',
    '{n} pages': '{n} page | {n} pages',
    '{n} signals': '{n} signal | {n} signals',
    '{value} {unit}': '{value} {unit}',
};
const datetimeUS = {
    short: {
        year: 'numeric', month: 'numeric', day: 'numeric',
    },
    long: {
        year: 'numeric', month: 'short', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
    },
};
export { messagesEN, datetimeUS };
